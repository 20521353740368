export default {
  locale: "no",

  MapLayout: {
    locale: "no",
    zoomInTip: "Zoom inn",
    zoomOutTip: "Zoom ut",
    trackTip: "Min posisjon",
    kartlagsVelger: "Velg kartlag",
    fullscrenTip: "Vis kart i fullskjerm",
    basemapGroup: "Bakgrunnskart",
    maplayerGroup: "Temakart",
    SearchPlaceholder: "Søk tur, anlegg og friluftsområder",

    infoBox:
      "I listen vises treff i kartet basert på valgene du har gjort i søkefilteret ovenfor. Utvalget endrer seg med kartutstnittet, med senter av kartet som fokuspunkt. Du kan zoome inn og bevege kartet for å spesifisere treffene ytterligere.",
    results: "Dine treff",
    showFriluftsrad: "Vis friluftsråd",
    showTipBox: "Vis tipsboks",
    tipboxContent:
      "<h3>Slik bruker du kartløsningen</h3>" +
      "<p><strong>OBS: Sjøkart på denne nettsiden må ikke benyttes til navigasjon.</strong></p>" +
      "<p>Klikk på den blå knappen med kompasset på for å zoome inn til stedet der du befinner deg akkurat nå. Dersom dette ikke virker må du tillate bruk av stedstjenester i nettleseren. På mobil finnes dette gjerne under Innstillinger.</p>" +
      "<p>Ved klikk i kart (på områdeavgrensing eller punkt) får du forslag til turer, fasiliteter og friluftsområder.</p>" +
      "<p>Når du går inn i kartet for første gang kommer alle punkter, områder og turer til å være aktive og vises som symboler i kartet. </p>" +
      "<p>For å filtrere klikker du på filterknappen oppe til høyre.</p>" +
      "<p>Bruk gjerne søkefeltet helt øverst til venstre for å finne informasjon om kjente turmål, områder eller fasiliteter.</p>" +
      "<p>Disse tipsene kan hentes frem igjen når du ønsker ved å klikke på den blå «Vis tipsboks»-knappen med informasjonstegnet til venstre i kartet.</p>",
    closeTipBox: "Ok, lukk tipsboks",
    dontshowTipBox: "Ikke vis igjen",
    sourceselectorText1: "1 valgt turtype",
    sourceselectorText2: "{0} valgte turtyper",
    seeMore: "Se mer",
    length: "Lengde",
    time: "Tid",
    season: "Sesong",
  },

  App: {
    locale: "no",
    zoomInTip: "Zoom inn",
    zoomOutTip: "Zoom ut",
    trackTip: "Min posisjon",
    basemapGroup: "Bakgrunnskart",
  },

  BannerContent: {
    title: "Vestlandets skjærgårdspark:",
    description:
      "Fra Åna-Sira i sør til Stadlandet i nord tilbys mer enn 300 friluftslivsområder, uthavner, fortøyningsplasser, teltplasser og badeplasser. Her er noe for enhver smak, enten du kommer med egen båt, kajakk eller bil!",
    map: "GÅ TIL KARTET",
  },

  AboutPreview: {
    title: "Vestkystparken ivaretar:",
    nature: "friluftsliv, natur- og kulturverdier",
    biology: "biologisk mangfold",
    natureGiven: "naturgitte næringsgrunnlag",
    esthetics: "estetiske verdier",
    health: "folkehelse",
    uu: "universell utforming",
    about: "OM VESTKYSTPARKEN",
  },

  BuoyInfo: {
    title: "Overnatting i kystleden:",
    description:
      "Friluftsrådene på Vestlandet tilbyr overnatting i kystleden. Bestill et kystledhus, hytte, en kabin – eller finn din egen teltplass, gapahuk eller padlehuk i kartet.",
    map: "TIL KYSTLEDEN",
  },

  OutportInfo: {
    title: "Stort utvalg uthavner:",
    description:
      "Vestkystparken kan lokke med godt tilrettelagte uthavner – med kaier, toalett, teltplasser og kyststier – såvel som mer urørte med kun fortøyningsbolter. Her er det mye å velge i, fra beskyttede bukter ut mot storhavet til poller og holmer!",
    map: "UTFORSK KARTET",
  },

  Nav: {
    seeAlso: "Se også",
    SearchPlaceholder: "Hva leter du etter?",
  },

  SearchResultComponent: {
    noResults: "Fant ingen treff. Kontroller stavemåte og prøv igjen",
  },

  Trips: {
    locale: "no",
    selectTourType: "Turforslag",
    turType: "Tur type",
    lettFottur: "Lett fottur",
    langFottur: "Lang fottur",
    klatretur: "Klatretur",
    sykleLangsVei: "Sykle langs vei",
    sykleITerreng: "Sykle i terreng",
    havpadling: "Havpadling",
    skiturIFjell: "Skitur i fjell",
    skiturPaFlatmark: "Skitur på flatmark",
    klarForAlt: "Klar for alt!",
    findTourOnMap: "Vis alle i kartet",
    addTourToMap: "Legg til din tur",
    Host: "Høst",
    Var: "Vår",
    Sommer: "Sommer",
    Vinter: "Vinter",
    Barmark: "Barmark",
    Snofore: "Snøføre",
    Bade_barmark_og_snofore: "Både barmark og snøføre",
    Alle: "Alle",
    Skitur: "Skitur",
    AnnenTur: "Andre turer",
    Jakttur: "Jakttur",
    Padletur: "Padletur/rotur",
    Fisketur: "Fisketur",
    Dykkertur: "Dykkertur",
    Sykkeltur: "Sykkeltur",
    Klatretur: "Klatretur",
    Aktivitet: "Aktivitet",
    Fottur: "Fottur",
    Trugetur: "Trugetur",
    AnnetFriluft: "Annet friluft",
    Attraksjon: "Attraksjoner",
    Badeplass: "Badeplass",
    Bru: "Bru",
    Batutsettplass: "Båtutsettingsplass",
    Gapahuk: "Gapahuk",
    Havn: "Havn/fortøyning",
    Infotavle: "Infotavle",
    JubileumsomradetFL: "Jubileumsområder FL",
    Rasteplass: "Rasteplass",
    Teltplass: "Teltplass",
    TilrettelagtFriluftsomrade: "Godt tilgjengelig friluftsområde",
    Utleiehytte: "Hytte",
    ApentHusvaere: "Åpen hytte",
  },

  Friluftselementer: {
    ChooseYourRecreationType: "Friluftsområder og anlegg",
    findRecreationOnMap: "Vis alle på kartet",
    "Annet friluft": "Annet friluft",
    Attraksjon: "Attraksjoner",
    Badeplass: "Badeplass",
    Bru: "Bru",
    Batutsettplass: "Båtutsettingsplass",
    Gapahuk: "Gapahuk",
    Havn: "Havn/fortøyning",
    Infotavle: "Infotavle",
    JubileumsomradetFL: "Jubileumsområder FL",
    Rasteplass: "Rasteplass",
    Teltplass: "Teltplass",
    TilrettelagtFriluftsomrade: "Godt tilgjengelig friluftsområde",
    Utleiehytte: "Hytte",
    ApentHusvaere: "Åpen hytte",
  },

  ArticleView: {
    searchForTrip: "Søk i turbeskrivelse",
    LoadMore: "Last flere",
    infoBox:
      "I listen vises treff i kartet basert på valgene du har gjort i søkefilteret ovenfor. Utvalget endrer seg med kartutstnittet, med senter av kartet som fokuspunkt. Du kan zoome inn og bevege kartet for å spesifisere treffene ytterligere.",
    results: "Dine treff",
    showFriluftsrad: "Vis friluftsråd",
    showTipBox: "Vis tipsboks",
    tipboxContent:
      "<h3>Slik bruker du kartløsningen</h3>" +
      "<p>Klikk på den blå knappen med kompasset på for å zoome inn til stedet der du befinner deg akkurat nå. Dersom dette ikke virker må du tillate bruk av stedstjenester i nettleseren. På mobil finnes dette gjerne under Innstillinger.</p>" +
      "<p>Ved klikk i kart (på områdeavgrensing eller punkt) får du forslag til turer, fasiliteter og friluftsområder.</p>" +
      "<p>Når du går inn i kartet for første gang kommer alle punkter, områder og turer til å være aktive og vises som symboler i kartet. </p>" +
      "<p>For å filtrere benytter du checkboksene til høyre (for desktop) eller under (for telefon)</p>" +
      "<p>Bruk gjerne søkefeltet helt øverst til venstre for å finne informasjon om kjente turmål, områder eller fasiliteter.</p>" +
      "<p>Søkefeltet er boksen med forstørrelesglass. Hvis du trykker på boksen, vil søkefeltet ekspandere.</p>" +
      '<p>Disse tipsene kan hentes frem igjen når du ønsker ved å klikke på den blå «Vis tipsboks»-knappen på kartet."</p>',
    closeTipBox: "Ok, lukk tipsboks",
    dontshowTipBox: "Ikke vis igjen",
    sourceselectorText1: "1 valgt turtype",
    sourceselectorText2: "{0} valgte turtyper",
    Title: "Alle turmål",
    filter: "Filter",
  },

  ArticleDetailView: {
    title: "Vestkystparken",
  },

  GodTurArticle: {
    bilder: "Bilder:",
    copyLinkBtn: "Kopier lenke",
    copyLink:
      "Kopier nettadressen nedenfor for å dele turmålet med dine venner via chat eller e-post.",
    linkCopied: "Lenke kopiert",
    copyLinkCloseBtn: "Lukk",
    elevation: "Høydemeter",
    length: "Lengde",
    points: "Poeng",
    startDate: "Startdato",
    endDate: "Sluttdato",
    RentalCabinsNearby: "Utleiehytter i området",
    time: "Tid",
    allYear: "Helårs",
    season: "Sesong",
    ShareTrip: "Del på:",
    gpxFile: "GPX-fil",
    "Annen tur": "Annen tur",
    Fisketur: "Fisketur",
    Fottur: "Fottur",
    Skitur: "Skitur",
    Padletur: "Padletur/rotur",
    Sykkeltur: "Sykkeltur",
    Badeplass: "Badeplass",
    Batutsettplass: "Båtutsettingsplass",
    Gapahuk: "Gapahuk",
    Havn: "Havn/fortøyning",
    Rasteplass: "Rasteplass",
    TilrettelagtFriluftsomrade: "Godt tilgjengelig friluftsområde",
    Utleiehytte: "Hytte",
    ApentHusvaere: "Åpen hytte",
    0: "Jubileumsområde",
    1: "Flytebrygge",
    2: "Kai",
    3: "Badeplass",
    4: "Fortøyningsbolter",
    5: "Båtutsettingsrampe",
    6: "Fortøyningsbøye",
    7: "Ferskvann",
    8: "Offentlig transport",
    9: "Toalett",
    10: "Padling",
    11: "Fiske",
    12: "Dykking",
    13: "Grill & bål",
    14: "Lek",
    15: "klatring",
    16: "Sykling",
    17: "Fottur",
    21: "Gapahuk",
    22: "Parkeringsplass",
    23: "Rasteplass",
    24: "Ankring",
  },

  GodTurArticleListComponent: {
    "Annen tur": "Andre turer",
    Fisketur: "Fisketur",
    Fottur: "Fottur",
    Skitur: "Skitur",
    Padletur: "Padletur/rotur",
    Sykkeltur: "Sykkeltur",
    Badeplass: "Badeplass",
    Batutsettplass: "Båtutsettingsplass",
    Gapahuk: "Gapahuk",
    Havn: "Havn/fortøyning",
    Rasteplass: "Rasteplass",
    TilrettelagtFriluftsomrade: "Godt tilgjengelig friluftsområde",
    Utleiehytte: "Hytte",
    ApentHusvaere: "Åpen hytte",
    Bru: "Bru",
    descriptionMissing: "Beskrivelse mangler",

    0: "Friluftsområde",
    5: "Badeplass",
    6: "Baderampe",
    8: "Balløkke",
    13: "Bål-/grillplass",
    14: "Båtutsettingsrampe",
    18: "Utsettingsplass kano-kajakk",
    20: "Ferskvann/drikkevann",
    21: "Fiskeplass fra land",
    22: "Flytebrygge",
    24: "Fortøyningsbolter",
    25: "Fortøyningsbøye",
    29: "Gapahuk",
    33: "Grillhytte",
    37: "Hinderløype-aktivitetsanlegg",
    40: "Kai",
    42: "klatring",
    47: "Lekeapparater",
    48: "Lekeplass",
    55: "Parkeringsplass uten ladestasjon",
    56: "Rasteplass-benker-bord",
    66: "Stupebrett",
    68: "Toalett",
    70: "Utedo",
    71: "Ankring",
    277: "Padletur",
    282: "Fottur",
    444: "Hytte",
    /**
    0: 'Friluftsområde',
    1: 'Flytebrygge',
    2: 'Kai',
    3: 'Badeplass',
    4: 'Fortøyningsbolter',
    5: 'Båtutsettingsrampe',
    6: 'Fortøyningsbøye',
    7: 'Ferskvann',
    9: 'Toalett',
    10: 'Padling',
    11: 'Fiske',
    12: 'Dykketur',
    13: 'Grill & bål',
    14: 'Lekeapparater',
    15: 'Klatrefelt',
    17: 'Fottur',
    21: 'Gapahuk',
    22: 'Parkeringsplass',
    23: 'Rasteplass-benker-bord',
    24: 'Ankring',

    111: 'Åpen hytte',
    444: 'Utleiehytte',
    555: 'Kystledhus' */
  },

  // Maa oppdateres

  vkpKategori: {
    0: "JUBILEUMSOMRÅDE",
    1: "FLYTEBRYGGE",
    2: "KAI",
    3: "BADEPLASS",
    4: "FORTØYNINGSBOLTER",
    5: "BÅTUTSETTINGSRAMPE",
    6: "FORTØYNINGSBØYE",
    7: "FERSKVANN",
    8: "OFFENTLIG TRANSPORT",
    9: "TOALETT",
    10: "PADLING",
    11: "FISKE",
    12: "DYKKING",
    13: "GRILL & BÅL",
    14: "LEK",
    15: "KLATRING",
    16: "SYKLING",
    17: "FOTTUR",
    21: "GAPAHUK",
    22: "PARKERINGSPLASS",
    23: "RASTEPLASS",
    24: "ANKRING",
    26: "TELTPLASS",
    111: "ÅPEN HYTTE",
    444: "UTLEIEHYTTE",
    555: "KYSTLEDHUS",
  },

  // symboler

  symbol: {
    0: "Friluftsområde",
    5: "Badeplass",
    6: "Baderampe",
    8: "Balløkke",
    13: "Bål-/grillplass",
    14: "Båtutsettingsrampe",

    18: "Utsettingsplass kano-kajakk",
    20: "Ferskvann/drikkevann",
    21: "Fiskeplass fra land",
    22: "Flytebrygge",
    24: "Fortøyningsbolter",
    25: "Fortøyningsbøye",
    29: "Gapahuk",
    33: "Grillhytte",
    37: "Hinderløype-aktivitetsanlegg",
    40: "Kai",
    42: "klatring",
    47: "Lekeapparater",
    48: "Lekeplass",
    55: "Parkeringsplass uten ladestasjon",
    56: "Rasteplass-benker-bord",
    66: "Stupebrett",
    67: "teltplass",
    68: "Toalett",
    70: "Utedo",
    71: "Ankring",
    277: "Padletur",
    282: "Fottur",
    444: "Hytte", // Tre kategorier
  },

  SlideMenu: {
    turfilter: "Visningfilter",
    vanskelihetsgrad: "Vanskelighetsgrad:",
    distanse: "Turlengde (km)",
    FilterIntroText:
      "Utvalget til høyre styres av kartutsnittet. Når du zoomer i kartet vil utvalget endres.",
    ShowSuggestions: "Vis turforslag",
    ShowOutdoorElements: "Vis friluftsområder og anlegg",
    ShowVkpElements: "Vis VKP",
    ShowuthavnerElements: "UTHAVNER",
    ShowaktiviteterElements: "AKTIVITETER",
    ShowovernattingElements: "OVERNATTING",
    ShowfasiliteterElements: "FASILITETER",
    lodging: "OVERNATTING",
    chooseOutdoorElementType: "Velg type",
    chooseTripType: "Velg type",
    showFilter: "Vis filter",
    hideFilter: "Skjul filter",
    menuButtonText: "Filter", //"Klikk for å velge hva som skal vises i kartet",
    ShowButtons: "DETALJER",
    summer: "SOMMER",
    winter: "VINTER",
    allyear: "HELÅRS",
    availability: "VIS TILGJENGELIG",
  },

  RangeInfo: {
    max: "Turer kortere enn {max} km.",
    min: "Turer lengre enn {min} km.",
    range: "Turer lengre enn {min} km og kortere enn {max} km.",
  },

  UserRegistrationPage: {
    UserRegistration: "Registrer ny brukerkonto",
    PasswordsDoNotMatch: "Passordene er ikke like",
    PasswordIsTooShort: "Passordet må være minimum 6 tegn",
    FirstnameNotValid: "Fornavn er ikke gyldig",
    SurnameNotValid: "Etternavn er ikke gyldig",
    MobileNotValid: "Mobilnummer er ikke gyldig",
    EmailAddressNotValid: "E-postadresse er ikke gyldig",
    organizationNotValid: "Organisasjon er ikke gyldig",
    CancelAndReturnToFrontPage: "Avbryt og gå tilbake til forsiden",
    ToLogin: "Til innlogging",
    UserRegistrationIntro:
      "Ved å registrere en brukerkonto på <strong>GodTur</strong> " +
      "kan du dele turtips med andre via bilder, tekst og en kartrute. " +
      "Det vil samtidig opprettes en brukerkonto for deg ved nettstedet " +
      "<strong>telltur.no</strong>. Her kan du plotte inn spesielle turkoder " +
      "fra utvalgte turer og slik konkurrere med andre turgåere om årspremier fra " +
      "friluftsrådet i din region. Brukernavn og passord for disse to nettstedene vil være det samme.",
    MandatoryFields: "Feltene i skjemaet merket med * er obligatoriske.",
    CreatedUser:
      "Vi har opprettet ny bruker til deg. Klikk på lenken under for å logge inn.",
  },

  UserRegistrationForm: {
    Register: "Opprett brukerkonto",
    Email: "E-postadresse* (dette blir ditt brukernavn for innlogging)",
    Password: "Passord*",
    PasswordRepeat: "Gjenta passordet*",
    Firstname: "Fornavn*",
    Surname: "Etternavn*",
    Mobile: "Mobiltelefonnummer",
    Organization: "Organisasjon",
    EmailPlaceholder: "Tast inn din e-postadresse",
    PasswordPlaceholder: "Tast inn et passord",
    PasswordRepeatPlaceholder: "Tast inn ditt valgte passord på nytt",
    FirstnamePlaceholder: "Tast inn fornavnet ditt",
    SurnamePlaceholder: "Tast inn etternavnet ditt",
    MobilePlaceholder: "Tast inn mobiltelefonnummeret ditt",
    OrganizationPlaceholder:
      "Dersom du er tilknyttet en GodTur-organisasjon oppgir du dette her",
    AcceptPrivacyPolicy:
      "Jeg har lest og godtar at data deles med samarbeidende tursider og at mine personlige data behandles i samsvar med ",
    PrivacyPolicy: "personvernerklæringen",
  },

  LoginPage: {
    LogIn: "Logg inn",
    LogInIntroText:
      "For å dele ditt turforslag med andre, må du ha opprettet en brukerkonto og være logget inn på GodTur. Har du ikke allerede laget deg en bruker på GodTur, kan du ",
    Username: "E-postadresse",
    Password: "Passord",
    ForgotPassword: "Glemt passord?",
    RegisterHere: "opprette en brukerkonto her.",
    Error:
      "Innloggingen feilet. Vennligst sjekk at e-post og passord er korrekt",
  },

  ResetPasswordPage: {
    Email: "E-postadresse",
    SendNewPassword: "Send nytt passord",
    Cancel: "Avbryt",
    CouldNotResetPassword:
      "Kunne ikke nullstille passord. Vennligst sjekk at e-postadressen er riktig",
    PasswordResetInfoText:
      "Nytt passord er sendt via e-post. Dersom du ikke mottar denne, sjekk om den kan ligge i søppelpostfilteret.",
    GoBack: "Gå tilbake til innlogging",
  },

  TranslatedServerError: {
    UserExists:
      "En bruker med denne e-postadressen finnes allerede. Hvis du tidligere har registrert en bruker på telltur.no eller godtur.no kan du benytte denne til å logge inn",
    InvalidUser:
      "Ugyldige brukerdata sendt inn. Forsøk å logge inn igjen på nytt.",
    InvalidOwner:
      "Du må være eier av objektet som skal endres. Ta kontakt med administrator.",
    EmailInUse: "Det er registrert en annen bruker med denne e-postadressen",
    SessionExpired: "Din sesjon har utløpt. Vennligst logg inn på nytt.",
    NoRecordFound: "Ingen data funnet",
    SaveFailed: "Lagring feilet",
    DeleteFailed: "Sletting mislyktes",
  },

  NavBar: {
    MyProfile: "Min side",
    LogOut: "Logg ut",
    LogIn: "Logg inn",
    AllTrips: "Vis alle turer",
    RegisterUser: "Registrer bruker",
    SearchPlaceholder: "Søk turforslag, anlegg og friluftsområder",
  },

  MyTripsList: {
    locale: "nb-NO",
    Title: "Tittel",
    Added: "Lagt til",
    Tools: "Verktøy",
    MyTrips: "Mine turer",
    NoTripsRegistered: "Ingen turer registrert",
    DeleteTrip: "Er du sikker på at du vil slette",
    Cancel: "Avbryt",
    Delete: "Slett",
  },

  MyProfileContainer: {
    MyPage: "Min side",
    FirstnameNotValid: "Fornavn er ikke gyldig",
    SurnameNotValid: "Etternavn er ikke gyldig",
    MobileNotValid: "Mobilnummer er ikke gyldig",
    EmailAddressNotValid: "E-postadresse er ikke gyldig",
    OrganizationNotValid: "Organisasjon er ikke gyldig",
  },

  MyProfileForm: {
    Save: "Lagre",
    Firstname: "Fornavn",
    Surname: "Etternavn",
    Mobile: "Mobiltelefonnummer",
    Email: "E-postadresse",
    Organization: "Organisasjon",
    ShowAndEditProfileDetails: "Vis og endre profildetaljer",
    HideProfileDetails: "Skjul profildetaljer",
    ConfirmDelete: "Jeg forstår, slett brukerkontoen min",
    DeleteAccountHeader: "Slett min GodTur (og TellTur) konto",
    DeleteGodTurAccount: `Du er nå i ferd med å slette din GodTur-brukerkonto.
      Du vil ikke lenger kunne logge inn eller redigere turer.
      Denne handlingen er ikke reversibel.`,
    DeleteTellTurAccount: `NB: Du er også i ferd med å slette din TellTur.no-brukerkonto.
      For å kunne delta i TellTur-konkurranser må du ha en brukerkonto.
      Opplysningene sikrer at turregistreringer knyttes til brukeren din,
      og gir friluftsrådene et verktøy for å hente ut ulike statistikker.
      Dersom du sletter brukerkontoen din vil alle opplysninger bli borte for godt,
      inkludert de turmål du har registrert.`,
    DeleteAccount: "Slett konto",
    ChangePassword: "Endre passord",
  },

  LastPublished: {
    lastPublished: "Siste turer",
    seeAll: "Se alle",
  },

  Activities: {
    locale: "nb-NO",
    AllActivities: "Alle aktiviteter",
  },

  GeomForm: {
    locale: "nb-NO",
    saveFailed: "Lagring feilet, prøv igjen",
    infoText:
      "Angi navn og beskrivelse om du vil. Navnet du oppgir vil vises i kartet sammen med hvert punkt og linje du har tegnet. I tillegg vil både navn og beskrivelse være med i GPX-filen som kan lastes ned for turen.",
    cancel: "Avbryt",
    save: "Lagre",
    continue: "Neste",
  },

  GodTurArticleCommentForm: {
    GiveRating: "Gi din vurdering:",
    Email: "E-post (vil ikke publiseres)",
    DisplayName: "Navn",
    Comment: "Kommentar",
    Cancel: "Avbryt",
    Submit: "Lagre",
    EmailIsMandatory: "E-postadresse er obligatorisk",
    DisplayNameIsMandatory: "Navn er obligatorisk",
    EmailAddressNotValid: "E-postadresse er ikke gyldig",
    RatingIsMandatory: "Vurdering er obligatorisk",
    RatingIsNotValid: "Vurdering er ikke gyldig",
  },

  GodTurArticleCommentSection: {
    RatingsIntro: "Les hva andre turgåere har å si - og ",
    RatingsLink: "legg igjen din kommentar!",
  },

  EditTripForm: {
    locale: "nb-NO",
    publish_disclaimer:
      "Ved å publisere turen aksepterer du at turen blir synlig på GodTur og samarbeidende tursider. Du kan når som helst trekke tilbake ditt samtykke ved å avpublisere eller slette turen.",
    publish: "Publiser",
    unpublish: "Avpubliser",
    mustDoBeforePublish:
      "For at du skal kunne publisere turen må du som minimum gi turen en tittel, laste opp minst ett bilde, legge inn en beskrivelse, angi type og sesong og plassering i kart (linje).",
    distance: "Avstand",
    timeSpent: "Tidsbruk",
    season: "Sesong",
    type: "Type",
    description: "Beskrivelse",
    municipality: "Kommune",
    selectMunicipality: "Velg kommune...",
    title: "Tittel",
    backToMyProfile: "Tilbake til Min side",
    editTrip: "Redigering tur",
    line: "Linje",
    media: "Media",
    Host: "Høst",
    Var: "Vår",
    Sommer: "Sommer",
    Vinter: "Vinter",
    Alle: "Alle",
    Barmark: "Barmark",
    Snofore: "Snøføre",
    Bade_barmark_og_snofore: "Både barmark og snøføre",
    Fisketur: "Fisketur",
    Fottur: "Fottur",
    Padletur: "Padletur/rotur",
    Skitur: "Skitur",
    Sykkeltur: "Sykkeltur",
    AnnenTur: "Annen tur",
  },

  MediaField: {
    caption: "Bildetekst",
    dragAndDrop: "Dra og slipp her eller klikk for å laste opp bilder",
    setAsMain: "Sett som hovedbilde",
  },

  PrivacyPage: {
    PrivacyStatement: "Personvernerklæring",
    AcceptPrivacyPolicy:
      "Jeg har lest og godtar at data deles med samarbeidende tursider og at mine personlige data behandles i samsvar med ",
    PrivacyPolicy: "personvernerklæringen",
    Accept: "Godkjenn",
    ConsentError:
      "Noe gikk galt ved registrering av din godkjennelse. Vennligst prøv igjen. Kontakt administrator dersom problemet vedvarer.",
  },

  Footer: {
    title: "KONTAKT OSS",
    contact: "Kontakt oss ",
    description: "Personvern",
    developedBy: "Utviklet av",
    privacy: "Personvern",
    avinet: "Asplan Viak Internet AS",
    avinetAddress: "https://avinet.no/",
  },

  MiniFooter: {
    contact: "Kontakt oss",
    privacy: "Personvern",
    avinet: "Asplan Viak Internet AS",
  },
};
