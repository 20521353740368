import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import {
  WmsLayer,
  provideMapState,
  LayerSwitch,
  LayerGroup,
  MapPadding,
  Zoom,
  olUtils,
} from "react-openlayers";
import { provideViewSize } from "../hocs/provideViewSize";
import InitExtentButton from "../components/InitExtentButton";
import PopupBox from "../components/PopupBox";
import { translate } from "react-translate";
import { Search } from "react-article-module";
import { withRouter } from "react-router-dom";
import Icon from "../components/Icon";
import "./MapLayout.scss";
import SearchResultComponent from "../components/SearchResultComponent";
import MapOverlay from "../components/MapOverlay";
import FeaturePopup from "../components/FeaturePopup";

class MapLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullscreen: false,
      searchOpen: false,
      showTipBox: true,
      tmpHideTipBox: false,
    };
    this.onFullScreenBtnClick = this.onFullScreenBtnClick.bind(this);
    this.onResultSelected = this.onResultSelected.bind(this);
    this.onTipBoxVisibilityChange = this.onTipBoxVisibilityChange.bind(this);
    this.changePopupClass = this.changePopupClass.bind(this);
  }

  componentWillMount() {
    const tipBoxDeactivated = localStorage.getItem("vkp_tipboxdeactivated");
    const tipboxTimestamp = localStorage.getItem("vkp_tipboxtimestamp");

    if (tipBoxDeactivated) {
      this.setState({ showTipBox: false });
    }

    if (tipboxTimestamp) {
      const diff = Math.abs(Date.now() - tipBoxDeactivated);
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      if (diffDays > 0) {
        this.setState({ tmpHideTipBox: true });
      }
    }
  }

  componentDidMount() {
    document
      .getElementsByClassName("react-openlayers--map")[0]
      .classList.remove("hide-map");
  }

  componentDidUpdate() {
    const { fullscreen } = this.state;

    if (fullscreen) {
      document
        .getElementsByClassName("react-openlayers--map")[0]
        .classList.add("map-fullscreen");
    } else {
      document
        .getElementsByClassName("react-openlayers--map")[0]
        .classList.remove("map-fullscreen");
    }
    this.props.forceUpdateMap();
  }

  onResultSelected(result) {
    const { history } = this.props;
    history.push(`/tur/${result.id.replace("_", "/")}`);
    this.setState({ searchOpen: false });
  }

  onFullScreenBtnClick() {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen });
  }

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [48, 0, viewHeight / 4, 0];
    }
    return [56, viewWidth / 2, 0, 0];
  }

  onTipBoxVisibilityChange(neverShowAgain) {
    if (neverShowAgain) {
      localStorage.setItem("vkp_tipboxdeactivated", true);
    } else {
      localStorage.removeItem("vkp_tipboxdeactivated");
      localStorage.setItem("vkp_tipboxtimestamp", Date.now());
    }
    this.setState({ showTipBox: !this.state.showTipBox });
  }

  changePopupClass(className) {
    this.setState({ popupClass: className });
  }

  render() {
    const { children, t, selectedFeature, articles } = this.props;
    const { searchOpen, showTipBox, tmpHideTipBox, popupClass } = this.state;
    const checkBoxChecked = localStorage.getItem("vkp_tipboxdeactivated");
    const mobileLayout = window.innerWidth < 700;
    const coords =
      selectedFeature &&
      olUtils.getCenterFromGeom(selectedFeature?.getGeometry());

    return (
      <div className="map-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <div className="popup-box-container">
          {showTipBox && !tmpHideTipBox && (
            <PopupBox
              onClose={this.onTipBoxVisibilityChange}
              closeBtnText={t("closeTipBox")}
              checkBoxText={t("dontshowTipBox")}
              checkBoxChecked={!!checkBoxChecked}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t("tipboxContent"),
                }}
              />
            </PopupBox>
          )}
          <div className="buttons">
            <button
              className="map-layout--tip-btn"
              onClick={() =>
                this.setState({ showTipBox: true, tmpHideTipBox: false })
              }
            >
              <Icon name="info" />
            </button>
          </div>
        </div>
        <NavBar />
        <div
          className={["map-layout--search", searchOpen ? "active" : ""].join(
            " "
          )}
        >
          <button
            className="map-layout--search-button"
            onClick={() =>
              this.setState((prevState) => ({
                searchOpen: !prevState.searchOpen,
              }))
            }
          >
            <Icon name={searchOpen ? "cross" : "search"} />
          </button>
          <Search
            onResultSelected={this.onResultSelected}
            placeholder={t("SearchPlaceholder")}
            isOpen={searchOpen}
            clearButtonIcon={<Icon name="cross" />}
            searchResult={SearchResultComponent}
          />
        </div>
        <InitExtentButton />
        <Zoom
          zoomInTipLabel={t("zoomInTip")}
          zoomOutTipLabel={t("zoomOutTip")}
        />
        <LayerSwitch tooltip={t("kartlagsVelger")}>
          <LayerGroup
            id="basemap_group"
            enabledLayers={["norgeskart_topo4"]}
            name={t("basemapGroup")}
            activeIcon={<Icon name="radioChecked" />}
            inactiveIcon={<Icon name="radioUnchecked" />}
            singleSelect
          >
            <WmsLayer
              key="norgeskart"
              name="Norgeskart"
              uri={[
                "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache",
                "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache",
                "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache",
              ]}
              id="norgeskart_topo4"
              layerName="topo4"
              version="1.1.1"
              zIndex={-1}
              useBaat
            />
            <WmsLayer
              key="sjokart"
              name="Sjøkart"
              uri={[
                "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache",
                "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache",
                "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache",
              ]}
              id="sjokartraster"
              layerName="sjokartraster"
              version="1.1.1"
              zIndex={-2}
              useBaat
            />
          </LayerGroup>
        </LayerSwitch>

        <WmsLayer
          key="hsukart"
          name="hsukart"
          id="hsukart"
          layerName={["ocean_3857"].join(",")}
          version="1.3.0"
          uri="http://map-adaptive3.avinet.no/mapserv.ashx?map=public\world&color=219%20255%20254"
          singleTile
          zIndex={-6}
          useBaat
        />
        {selectedFeature &&
          (mobileLayout ? (
            <FeaturePopup
              selectedFeature={selectedFeature}
              articles={articles}
              history={this.props.history}
              onArticleClose={this.props.onArticleClose}
              t={t}
              popupClass={popupClass}
            />
          ) : (
            <MapOverlay
              coords={coords}
              selectedFeature={selectedFeature}
              changePopupClass={this.changePopupClass}
            >
              <FeaturePopup
                selectedFeature={selectedFeature}
                articles={articles}
                history={this.props.history}
                onArticleClose={this.props.onArticleClose}
                t={t}
                popupClass={popupClass}
              />
            </MapOverlay>
          ))}
        <div>
          <div className="map-layout--components">{children}</div>
        </div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.element,
  t: PropTypes.func.isRequired,
  forceUpdateMap: PropTypes.func.isRequired,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
  history: PropTypes.object,
};

export default provideViewSize(
  provideMapState(withRouter(translate("MapLayout")(MapLayout)))
);
