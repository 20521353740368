import React from "react";
import PropTypes from "prop-types";
import { Map } from "react-openlayers";
import { translate } from "react-translate";
import config from "../config/index";

class MapWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: window.innerWidth > 768 ? [1000000, 8400000] : [700000, 7900000],
      zoom: window.innerWidth > 768 ? 7 : 6,
      searchOpen: true,
    };
  }

  render() {
    const { center, zoom } = this.state;
    const { t, children } = this.props;

    return (
      <Map
        center={center}
        zoom={zoom}
        minZoom={config.minZoom}
        maxZoom={config.maxZoom}
        track
        baatUrl={config.adaptiveUrl}
        trackTipLabel={t("trackTip")}
        hitTolerance={20}
      >
        {children}
      </Map>
    );
  }
}

MapWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.element,
};

export default translate("App")(MapWrapper);
