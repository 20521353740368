import request from "superagent";
import { getSessionToken, authReducerActions } from "react-adaptive-auth";
import { fixKeyValueArraysToJsObject } from "../utils/utils";
import config from "../config/index";

// ------------------------------------
// Constants
// ------------------------------------
export const DIGI_THEME_MEDIA_SAVING = "DIGI_THEME_MEDIA_SAVING";
export const DIGI_THEME_MEDIA_SAVE_SUCCESS = "DIGI_THEME_MEDIA_SAVE_SUCCESS";
export const DIGI_THEME_MEDIA_SAVE_ERROR = "DIGI_THEME_MEDIA_SAVE_ERROR";
export const DIGI_THEME_MEDIA_READING = "DIGI_THEME_MEDIA_READING";
export const DIGI_THEME_MEDIA_READ_SUCCESS = "DIGI_THEME_MEDIA_READ_SUCCCESS";
export const DIGI_THEME_MEDIA_READ_ERROR = "DIGI_THEME_MEDIA_READ_ERROR";
export const DIGI_THEME_MEDIA_DELETING = "DIGI_THEME_MEDIA_DELETING";
export const DIGI_THEME_MEDIA_DELETE_SUCCESS =
  "DIGI_THEME_MEDIA_DELETE_SUCCESS";
export const DIGI_THEME_MEDIA_DELETE_ERROR = "DIGI_THEME_MEDIA_DELETE_ERROR";
export const DIGI_THEME_MEDIA_RESET = "DIGI_THEME_MEDIA_RESET";

// ------------------------------------
// Actions
// ------------------------------------
const saving = () => ({ type: DIGI_THEME_MEDIA_SAVING });

const saveSuccess = (data) => ({
  type: DIGI_THEME_MEDIA_SAVE_SUCCESS,
  payload: {
    data,
  },
});

const saveFailed = (error) => ({
  type: DIGI_THEME_MEDIA_SAVE_ERROR,
  payload: {
    error,
  },
});

const reading = () => ({ type: DIGI_THEME_MEDIA_READING });

const readSuccess = (records, total) => ({
  type: DIGI_THEME_MEDIA_READ_SUCCESS,
  payload: {
    records,
    total,
  },
});

const readFailed = (error) => ({
  type: DIGI_THEME_MEDIA_READ_ERROR,
  payload: {
    error,
  },
});

const deleting = () => ({ type: DIGI_THEME_MEDIA_DELETING });

const deleteSuccess = () => ({ type: DIGI_THEME_MEDIA_DELETE_SUCCESS });

const deleteFailed = (error) => ({
  type: DIGI_THEME_MEDIA_DELETE_ERROR,
  payload: {
    error,
  },
});

export const resetMedia = () => ({ type: DIGI_THEME_MEDIA_RESET });

// ------------------------------------
// Action creators
// ------------------------------------
export const readMedia = (recordUuid, srid) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(reading());
    const themeUuid = getReducerState().themeUuid;
    request
      .post(config.adaptiveUrl + "WebServices/generic/Media.asmx/Read")
      .send({
        extraParams: [
          {
            key: "owner_uuid",
            value: recordUuid,
          },
          {
            key: "parent_object_uuid",
            value: themeUuid,
          },
        ],
        filter: {
          filterColumns: [],
          defaultFilter: [],
          secondFilter: [],
        },
        limit: 100,
        page: 1,
        start: 0,
      })
      .set("X-Adaptive-SRID", srid)
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .then((res) => res.body)
      .then((res) => {
        res = res.d;
        if (res.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }
        if (!res.success) {
          dispatch(
            readFailed({
              code: "NO_RECORD_FOUND",
              message: res.exception.message,
            })
          );
        }

        dispatch(readSuccess(res.records, res.total));
      })
      .catch((e) => {
        dispatch(readFailed(e || { message: "readFailed" }));
      });
  };
};

export const updateMedia = (record) => {
  return (dispatch) => {
    dispatch(saving());
    request
      .post(config.adaptiveUrl + "WebServices/generic/Media.asmx/Update")
      .send({ data: record, extraParams: [] })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .then((res) => res.body)
      .then((res) => {
        res = res.d;
        if (res.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }
        if (!res.success) {
          dispatch(
            saveFailed({ code: "SAVE_FAILED", message: res.exception.message })
          );
        }

        dispatch(saveSuccess());
      })
      .catch((e) => {
        dispatch(saveFailed(e || { message: "readFailed" }));
      });
  };
};

export const deleteMedia = (mediaUuid) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(deleting());
    // const themeUuid = getReducerState().themeUuid;
    request
      .post(config.adaptiveUrl + "WebServices/generic/Media.asmx/Destroy")
      .send({ uuid: mediaUuid, extraParams: [] })
      .set("Accept", "application/json")
      .set("gm_session_id", getSessionToken())
      .then((res) => res.body)
      .then((res) => {
        res = res.d;
        if (res.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }
        if (!res.success) {
          dispatch(
            deleteFailed({
              code: "DELETE_FAILED",
              message: res.exception.message,
            })
          );
        }

        dispatch(deleteSuccess());
      })
      .catch((e) => {
        dispatch(deleteFailed(e || { message: "readFailed" }));
      });
  };
};

export const saveMedia = (files, recordUuid, srid) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(saving());

    create(dispatch, files, recordUuid, getReducerState().themeUuid, srid);
  };
};

const create = (dispatch, files, recordUuid, themeUuid, srid, iterator = 0) => {
  const mediaFile = files[iterator];
  const media = {
    name: mediaFile.name,
    mime: mediaFile.type,
    owner_uuid: recordUuid,
    parent_object_type: "digi_theme",
    parent_object_uuid: themeUuid,
    config: { configs: [{ key: "isPublic", value: true }] },
  };
  request
    .post(config.adaptiveUrl + "WebServices/generic/Media.asmx/Create")
    .send({ data: media, extraParams: [] })
    .set("X-Adaptive-SRID", srid)
    .set("Accept", "application/json")
    .set("gm_session_id", getSessionToken())
    .then((res) => res.body)
    .then((res) => {
      res = res.d;

      if (res.sessionExpired) {
        dispatch(authReducerActions.sessionExpired());
      }

      if (!res.success) {
        dispatch(
          saveFailed({ code: "SAVE_FAILED", message: res.exception.message })
        );
      }

      const dataObj = fixKeyValueArraysToJsObject(res.data).find(
        (r) => r.type === "Cartomatic.Utils.OpsIO.DataObj"
      );
      if (dataObj.uuid) {
        upload(
          dispatch,
          files,
          dataObj.uuid,
          recordUuid,
          themeUuid,
          srid,
          iterator
        );
      }
    })
    .catch((e) => {
      dispatch(saveFailed(e || { message: "saveFailed" }));
    });
};

const upload = (
  dispatch,
  files,
  uuid,
  recordUuid,
  themeUuid,
  srid,
  iterator
) => {
  const url =
    config.adaptiveUrl +
    "WebServices/generic/Media.asmx/Upload?" +
    "uuid=" +
    uuid +
    "&gm_session_id=" +
    getSessionToken();
  const file = files[iterator];

  request
    .post(url)
    .attach("file", file)
    .set("Accept", "application/json")
    .then((res) => JSON.parse(res.text))
    .then((res) => {
      if (res.sessionExpired) {
        dispatch(authReducerActions.sessionExpired());
      }

      if (!res.success) {
        dispatch(
          saveFailed({ code: "SAVE_FAILED", message: res.exception.message })
        );
      }
      if (files.length - 1 !== iterator) {
        create(dispatch, files, recordUuid, themeUuid, srid, iterator + 1);
      } else {
        dispatch(saveSuccess());
      }
    })
    .catch((e) => {
      dispatch(saveFailed(e || { message: "saveFailed" }));
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  deleting: false,
  loading: false,
  error: undefined,
  records: [],
  total: undefined,
};

export default (key, themeUuid) => {
  return (state = initialState, action) => {
    switch (action.type) {
      case key + "@@" + DIGI_THEME_MEDIA_SAVING:
        return Object.assign({}, state, {
          deleting: true,
          error: undefined,
        });
      case key + "@@" + DIGI_THEME_MEDIA_SAVE_SUCCESS:
        return Object.assign({}, state, {
          deleting: false,
        });
      case key + "@@" + DIGI_THEME_MEDIA_SAVE_ERROR:
        return Object.assign({}, state, {
          deleting: false,
          error: action.payload.error,
        });
      case key + "@@" + DIGI_THEME_MEDIA_READING:
        return Object.assign({}, state, {
          loading: true,
          records: [],
          error: undefined,
        });
      case key + "@@" + DIGI_THEME_MEDIA_READ_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          records: action.payload.records,
          total: action.payload.total,
        });
      case key + "@@" + DIGI_THEME_MEDIA_READ_ERROR:
        return Object.assign({}, state, {
          loading: false,
          error: action.payload.error,
        });
      case key + "@@" + DIGI_THEME_MEDIA_DELETING:
        return Object.assign({}, state, {
          deleting: true,
          error: undefined,
        });
      case key + "@@" + DIGI_THEME_MEDIA_DELETE_SUCCESS:
        return Object.assign({}, state, {
          deleting: false,
        });
      case key + "@@" + DIGI_THEME_MEDIA_DELETE_ERROR:
        return Object.assign({}, state, {
          deleting: false,
          error: action.payload.error,
        });
      case key + "@@" + DIGI_THEME_MEDIA_RESET:
        return Object.assign({}, state, initialState);
      default:
        return state ? { ...state, themeUuid } : { ...initialState, themeUuid };
    }
  };
};
