/** @format */

import React from "react";
import PropTypes from "prop-types";
import Title from "../Title";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { readMedia } from "../../redux/mediaReducer";
import SingleCarousel from "../SingleCarousel";
import { bindActionCreators as bindScopedActionCreators } from "../../utils/scopedReducer";
import "./GodTurArticle.scss";
import { olUtils, provideMapState, MapPadding } from "react-openlayers";
import { provideViewSize } from "../../hocs/provideViewSize";
import TripGeometryContainer from "../../containers/TripGeometryContainer";
import config from "../../config/index";

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    {},
    bindScopedActionCreators({ readMedia }, dispatch, "mediaTrips")
  );
};

const mapStateToProps = (state) => ({
  municipalities: state.municipalities.records,
  municipalitiesLoading: state.municipalities.loading,
  mediaLoading: state.mediaTrips.loading,
  mediaFiles: state.mediaTrips.records,
  sessionToken: state.auth.sessionToken,
});

function getArticleId(props) {
  if (props && props.article && props.article.id) {
    var arr = props.article.id.split("_");
    if (arr.length > 0) {
      return arr[arr.length - 1];
    }
  }
  return undefined;
}

class GodTurArticle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      municipalityName: "",
      initialized: false,
    };

    this.updateMunicipalityName = this.updateMunicipalityName.bind(this);
    this.zoomToExtent = this.zoomToExtent.bind(this);
  }

  calcMapPadding() {
    const { viewHeight } = this.props;

    return [0, 0, viewHeight / 2, 0];
  }

  async componentWillMount() {
    const id = getArticleId(this.props);
    if (id) {
      this.updateMunicipalityName(this.props);
    }
  }

  componentDidMount() {
    this.checkArticle(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkArticle(this.props);
    const prevId = getArticleId(prevProps);
    const id = getArticleId(this.props);

    if (!prevId && id) {
      this.updateMunicipalityName(this.props);
    }

    if (
      !prevState.initialized &&
      this.state.initialized &&
      this.props.article
    ) {
      this.props.readMedia(
        this.props.article.properties.uuid,
        config.mapProjCode
      );
    }
  }

  zoomToExtent(tripLines) {
    const { fitViewAndZoom } = this.props;

    const features =
      tripLines &&
      tripLines.map((geom) => {
        const f = olUtils.createFeatureFromWkt(geom.geom_wkt, geom);
        return f;
      });

    if (features.length) {
      const extent = olUtils.bufferExtent(
        olUtils.getExtentFromFeatures(features),
        1.2
      );
      setTimeout(() => fitViewAndZoom(extent), 100);
    }
  }

  checkArticle(props) {
    const { initialized } = this.state;

    if (
      props.article &&
      props.article.id &&
      props.article.id !== "" &&
      !initialized
    ) {
      this.setState({ initialized: true });
    }
  }

  updateMunicipalityName(props) {
    const municipality = props.municipalities.find((m) => {
      return m.komm.toString() === props.article.properties.municipality;
    });
    if (municipality) {
      this.setState({ municipalityName: municipality.navn });
    }
  }

  formatContent(content) {
    return content && content.replace(/\n/g, "</br>");
  }

  render() {
    const {
      id,
      title,
      content,
      t,
      mediaFiles,
      mediaLoading,
      // eslint-disable-next-line camelcase
      type_friluftselement,
      author,
      article,
      lengde,
      sesong,
      tid,
      // eslint-disable-next-line camelcase
      bilde_multimedia,
    } = this.props;

    var contentType = type_friluftselement;

    if (content.length > 0) {
      contentType = content;
    }

    /* eslint-disable camelcase */
    const image =
      (id.startsWith("05") || id.startsWith("06")) && bilde_multimedia
        ? `https://bof.avinet.no/WebServices/generic/Media.asmx/Download?uuid=${bilde_multimedia}&thumbnail_size=small`
        : bilde_multimedia &&
          `https://friluftsforvaltning.avinet.no/WebServices/generic/Media.asmx/Download?uuid=${bilde_multimedia}&thumbnail_size=small`;
    /* eslint-disable camelcase */

    return (
      <div>
        <div className="type-title-bg">
          {type_friluftselement && (
            <div className="godtur--article-type">{type_friluftselement}</div>
          )}
        </div>
        <div className="godtur--article">
          <div className="godtur--article-flex-container">
            <MapPadding padding={this.calcMapPadding()} />
            <Title title={title} />
            <article className="godtur--article--hero">
              <div className="godtur--article-content">
                <div className="godtur--article-content-title">
                  <div className="title-details">
                    {title && title !== null && <h1>{title}</h1>}
                  </div>
                </div>

                <div
                  className="godtur--article-content-meta"
                  dangerouslySetInnerHTML={{
                    __html: this.formatContent(contentType),
                  }}
                />
                <div className="godtur--article-content-meta.small">
                  <p>{author}</p>
                </div>
              </div>
            </article>
            <aside>
              <div className="img-container-div">
                <b>
                  <p>{image && t("bilder")}</p>
                </b>
                <SingleCarousel
                  key="godtur-article--carousel"
                  mainImage={image}
                  images={mediaFiles}
                  loading={mediaLoading}
                />
              </div>
              <div className="tripinfo-container-div">
                <div className="godtur-article-flex-box">
                  <div className="godtur-article-meta-cols">
                    <span className="godtur-article--meta-cols-label">
                      {t("length")}
                    </span>
                    <span className="godtur-article--meta-cols-value">
                      <b>
                        {lengde && lengde >= 1000
                          ? `${(lengde / 1000).toFixed(1)} km`
                          : `${lengde} m`}
                      </b>
                    </span>
                  </div>
                  <div className="godtur-article-meta-cols">
                    <span className="godtur-article--meta-cols-label">
                      {t("time")}
                    </span>
                    <span className="godtur-article--meta-cols-value">
                      <b>{tid && tid}</b>
                    </span>
                  </div>
                  <div className="godtur-article-meta-cols">
                    <span className="godtur-article--meta-cols-label">
                      {t("season")}
                    </span>
                    <span className="godtur-article--meta-cols-value">
                      <b>{sesong && sesong}</b>
                    </span>
                  </div>
                </div>
              </div>
            </aside>
            {this.props.godtur_id && (
              <TripGeometryContainer
                tripId={this.props.godtur_id}
                motherGeom={article.olFeature}
                zoomToExtent={this.zoomToExtent}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

GodTurArticle.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  content: PropTypes.string,
  bilde_multimedia: PropTypes.string,
  id: PropTypes.string,
  lengde: PropTypes.string,
  tid: PropTypes.string,
  t: PropTypes.func.isRequired,
  sesong: PropTypes.string,
  article: PropTypes.object,
  mediaLoading: PropTypes.bool.isRequired,
  mediaFiles: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  godtur_id: PropTypes.string,
  viewHeight: PropTypes.number,
  fitViewAndZoom: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideViewSize(provideMapState(translate("GodTurArticle")(GodTurArticle))));
