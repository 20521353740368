import React from "react";
import PropTypes from "prop-types";
import Title from "../Title";
import { connect } from "react-redux";
import { bindActionCreators as bindScopedActionCreators } from "../../utils/scopedReducer";
import { translate } from "react-translate";
import { readFiltered } from "../../redux/digiThemeReducer";
import { withRouter } from "react-router-dom";
import featureStyle from "../../utils/featureStyle";
import {
  olUtils,
  VectorSource,
  provideMapState,
  ClusterSource,
  clusterStackStyle,
  clusterText,
  MapPadding,
} from "react-openlayers";
import { provideViewSize } from "../../hocs/provideViewSize";
import { Style, Circle, Fill, Stroke } from "ol/style";
import config from "../../config/index";
import BadeTemp from "../BadeTemp";
import BadeTempBkk from "../BadeTempBkk";

import "./FriluftselementArticle.scss";
import ImageCarousel from "../ImageCarousel";
import VimeoVideo from "../VimeoVideo";

const mapDispatchToProps = (dispatch) => {
  return {
    readAllVkpElements: bindScopedActionCreators(
      readFiltered,
      dispatch,
      "vkpElementGeomReducer"
    ),
  };
};

const mapStateToProps = (state) => ({
  mediaLoading: state.mediaFriluft.loading,
  mediaFiles: state.mediaFriluft.records,
  vkpElementGeoms: state.vkpElementGeomReducer.records,
  vkpElementGeomsLoading: state.vkpElementGeomReducer.loading,
  filterColumn: state.filterReducer,
});

export const clusterStyle = clusterStackStyle({
  clusterLabelText: clusterText({ color: "#333" }),
  clusterLabelOffsetX: 10, // 0 is on top of each other.
  clusterLabelOffsetY: 20, // 0 is on top of each other.
  typeField: "type",
  featureStyle,
});

class GodTurArticle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      averageRating: 0,
    };
  }

  calcMapPadding() {
    const { viewHeight } = this.props;

    return [0, 0, viewHeight / 2, 0];
  }

  componentDidMount() {
    if (this.props.vkpElementGeoms && this.props.vkpElementGeoms.length === 0) {
      this.readVkpElements();
    }
  }

  readVkpElements() {
    const { readAllVkpElements, vkpElementGeomsLoading } = this.props;
    const me = this;
    if (vkpElementGeomsLoading) {
      setTimeout(() => me.readVkpElements(), 50);
      return;
    }
    readAllVkpElements(
      config.vestkystparkElementerPublic.columns,
      null,
      config.mapProjCode,
      0,
      0
    );
    this.setState({
      shouldUpdateVkpElementFeatures: true,
    });
  }

  formatContent(content) {
    return content && content.replace(/\n/g, "</br>");
  }

  getFeatureStyle() {
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: "rgb(204, 23, 134)",
        }),
        radius: 10,
      }),
    });
  }

  getFeatureStylePolygon() {
    return new Style({
      fill: new Fill({
        color: "rgba(65, 90, 149, 0.9)",
      }),
      stroke: new Stroke({
        color: "rgba(65, 90, 149)",
      }),
    });
  }

  get getLinks() {
    const {
      tittel1,
      tittel2,
      tittel3,
      tittel4,
      tittel5,
      lenke1,
      lenke2,
      lenke3,
      lenke4,
      lenke5,
    } = this.props;

    const arr = [];

    if (lenke1 && tittel1) {
      arr.push({ name: tittel1, url: lenke1 });
    }
    if (lenke2 && tittel2) {
      arr.push({ name: tittel2, url: lenke2 });
    }

    if (lenke3 && tittel2) {
      arr.push({ name: tittel3, url: lenke3 });
    }

    if (lenke4 && tittel4) {
      arr.push({ name: tittel4, url: lenke4 });
    }

    if (lenke5 && tittel5) {
      arr.push({ name: tittel5, url: lenke5 });
    }

    return arr;
  }

  render() {
    const { title, content, type_friluftselement, article } = this.props;

    const links = this.getLinks;

    const features =
      this.props.vkpElementGeoms &&
      this.props.vkpElementGeoms
        .filter((e) => e.datakilde !== 2)
        .map((geom) => {
          const f = olUtils.createFeatureFromWkt(geom.geom_wkt, {
            dataIndex: config.vestkystparkElementerPublic.dataIndex,
            id: config.vestkystparkElementerPublic.dataIndex + "_" + geom.id,
            type: geom.type,
            symbol_id: geom.symbol_id,
            datakilde: geom.datakilde,
          });
          return f;
        });

    const vimeoUrl = article.properties.vimeo_url;

    return (
      <div>
        <div className="type-title-bg">
          {type_friluftselement && (
            <div className="godtur--article-type">{type_friluftselement}</div>
          )}
        </div>
        <div className="godtur--article">
          <div className="article-image-carusel">
            <ImageCarousel record={this.props.article} />
          </div>
          <div className="godtur--article-flex-container">
            <MapPadding padding={this.calcMapPadding()} />
            {article.properties.datakilde === 2 ? (
              <React.Fragment>
                <ClusterSource
                  key="symbol-ontop-polygon"
                  layerName="symbol-ontop-polygon"
                  features={features}
                  style={clusterStyle}
                  clusterThreshold={100}
                  zIndex={100}
                />
                <VectorSource
                  layerName="friluftselement-polygon"
                  features={[
                    olUtils.createFeatureFromWkt(
                      article.properties.geom_omraade
                    ),
                  ]}
                  styleFn={() => this.getFeatureStylePolygon}
                  zIndex={0}
                />
              </React.Fragment>
            ) : (
              <VectorSource
                layerName="friluftselement-vector-layer"
                features={[article.olFeature]}
                styleFn={() => this.getFeatureStyle}
              />
            )}
            <Title title={title} />
            {/* TODO refactor code */}
            <article className="godtur--article--hero">
              <div className="godtur--article-content">
                <div className="godtur--article-content-title">
                  <div className="title-details">
                    {title && title !== null && <h1>{title}</h1>}
                  </div>
                </div>
                {article.properties && article.properties.badetemp_id && (
                  <BadeTemp id={article.properties.badetemp_id} />
                )}
                {article.properties && article.properties.badetemp_bkk && (
                  <BadeTempBkk id={article.properties.badetemp_bkk} />
                )}
                <div
                  className={
                    "godtur--article-content-meta" +
                    (article.properties.datakilde === 1 ? " punkt" : "")
                  }
                  dangerouslySetInnerHTML={{
                    __html: this.formatContent(content),
                  }}
                />

                {links.length > 0 && (
                  <div className="godtur--article-content-meta">
                    <p>Linker</p>
                    {links.map((item, i) => (
                      <p key={i}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </article>
          </div>
          {vimeoUrl && vimeoUrl !== "" && <VimeoVideo id={vimeoUrl} />}
        </div>
      </div>
    );
  }
}

GodTurArticle.propTypes = {
  // eslint-disable-next-line
  bilde_multimedia: PropTypes.string,
  id: PropTypes.string,
  statskog_url: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  article: PropTypes.object,
  type: PropTypes.string,
  tittel1: PropTypes.string,
  tittel2: PropTypes.string,
  tittel3: PropTypes.string,
  tittel4: PropTypes.string,
  tittel5: PropTypes.string,
  lenke1: PropTypes.string,
  lenke2: PropTypes.string,
  lenke3: PropTypes.string,
  lenke4: PropTypes.string,
  lenke5: PropTypes.string,
  vkpElementGeoms: PropTypes.array,
  readAllVkpElements: PropTypes.func.isRequired,
  vkpElementGeomsLoading: PropTypes.bool,
  viewHeight: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  provideViewSize(
    withRouter(provideMapState(translate("GodTurArticle")(GodTurArticle)))
  )
);
