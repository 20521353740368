import React from "react";
import PropTypes from "prop-types";
import PageView from "../views/PageView";
import ArticleView from "../views/ArticleView";
import ArticleDetailView from "../views/ArticleDetailView";
import PageLayout from "../layouts/PageLayout";
import IndexLayout from "../layouts/IndexLayout";
import MapLayout from "../layouts/MapLayout";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { readFiltered } from "../redux/digiThemeReducer";
import { bindActionCreators } from "../utils/scopedReducer";
import config from "../config/index";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ readFiltered }, dispatch, "municipalities");
};
const mapStateToProps = (state) => ({
  municipalities: state.municipalities.records,
  municipalitiesLoading: state.municipalities.loading,
  articles: state.articles.articles,
});

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.onArticleOpen = this.onArticleOpen.bind(this);
    this.onArticleClose = this.onArticleClose.bind(this);
    this.state = {
      selectedFeature: undefined,
    };
  }
  componentDidMount() {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      splashScreen.remove();
    }
    const cfg = config;
    this.props.readFiltered(
      cfg.dtMunicipality.columns,
      null,
      cfg.mapProjCode,
      0,
      1000,
      null
    );
  }

  onArticleOpen(article) {
    this.setState({ selectedFeature: article });
  }

  onArticleClose() {
    this.setState({ selectedFeature: null });
  }

  render() {
    const { articles } = this.props;

    return (
      <Router basename={process.env.PUBLIC_URL + "/"}>
        <Switch>
          <Route path="/alleturer">
            <MapLayout
              selectedFeature={this.state.selectedFeature}
              articles={articles}
              onArticleClose={this.onArticleClose}
            >
              <Switch>
                <Route
                  path="/alleturer/:activeSources*"
                  render={(props) => (
                    <ArticleView
                      {...props}
                      onArticleOpen={this.onArticleOpen}
                    />
                  )}
                />
              </Switch>
            </MapLayout>
          </Route>
          <Route path="/tur">
            <MapLayout>
              <Switch>
                <Route
                  path="/tur/:sourceId/:articleId"
                  component={ArticleDetailView}
                />
              </Switch>
            </MapLayout>
          </Route>

          <Route path="/:params">
            <PageLayout>
              <Switch>
                <Route path="/:params" component={PageView} />
              </Switch>
            </PageLayout>
          </Route>

          <Route path="/">
            <IndexLayout>
              <Switch>
                <Route exact path="/index" component={PageView} />
                <Route exact path="/" component={PageView} />
              </Switch>
            </IndexLayout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

AppRouter.propTypes = {
  readFiltered: PropTypes.func,
  onArticleOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
