import React from "react";
import { translate } from "react-translate";

class SearchResultComponent extends React.Component {
  render() {
    const { result, onResultSelected, t } = this.props;

    return (
      <li
        key={result.id}
        className="clearfix"
        onClick={() => onResultSelected(result)}
        onMouseOver={() =>
          result.olFeature && result.olFeature.set("hover", true)
        }
        onMouseOut={() =>
          result.olFeature && result.olFeature.set("hover", false)
        }
      >
        {result.length === 0 ? (
          <p className="no-results">{t("noResults")}</p>
        ) : (
          <>
            <h3>{result.properties.title}</h3>
            {result.properties.content && (
              <p className="searchSource--content">
                {result.properties.content
                  .replace(/<.*?>/g, "")
                  .replace(/(&.+;)/gi, " ")
                  .substring(0, 150)}
              </p>
            )}
            <p className="searchSource--provider">
              {result.properties.provider}
            </p>
          </>
        )}
      </li>
    );
  }
}

export default translate("SearchResultComponent")(SearchResultComponent);
