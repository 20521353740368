import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { readChildren, resetDigiTheme } from "../redux/digiThemeReducer";
import { bindActionCreators as bindScopedActionCreators } from "../utils/scopedReducer";
import { Style, Circle, Fill, Stroke, Text } from "ol/style";
import { olUtils, VectorSource } from "react-openlayers";
import config from "../config/index";

export const pointStyle = (f) => {
  const zIndex = 0;
  const name = f.get("name");
  return new Circle({
    fill: new Fill({
      color: "#007681",
    }),
    stroke: new Stroke({
      color: "#003b5c",
    }),
    text: new Text({
      text: name || "Ikke tilgjengelig",
      fill: new Fill({
        color: "white",
      }),
      stroke: new Stroke({
        color: "grey",
      }),
      offsetX: 20,
      offsetY: 0,
      backgroundFill: new Fill({
        color: "#003b5c",
      }),
      textAlign: "left",
      font: "14px sans-serif",
      padding: [5, 5, 5, 5],
    }),
    zIndex,
  });
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    {},
    bindScopedActionCreators(
      {
        pointsReadChildren: readChildren,
        resetPointChildrenDt: resetDigiTheme,
      },
      dispatch,
      "tripPoints"
    ),
    bindScopedActionCreators(
      { linesReadChildren: readChildren, resetLineChildrenDt: resetDigiTheme },
      dispatch,
      "tripLines"
    )
  );
};

const mapStateToProps = (state) => ({
  tripPoints: state.tripPoints,
  tripLines: state.tripLines,
});

class TripGeometryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.readPoints = this.readPoints.bind(this);
    this.readLines = this.readLines.bind(this);
    this.getFeatureStyleTrip = this.getFeatureStyleTrip.bind(this);
    this.getFeatureStylePoint = this.getFeatureStylePoint.bind(this);
  }

  componentDidMount() {
    const id = this.props.tripId;
    if (id) {
      this.readPoints(id);
      this.readLines(id);
    }
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.tripId;
    const id = this.props.tripId;

    if (!prevId && id) {
      this.readPoints(id);
      this.readLines(id);
    }
  }

  componentWillUnmount() {
    this.props.resetLineChildrenDt();
    this.props.resetPointChildrenDt();
  }

  readPoints(id) {
    if (id && typeof id === "string") {
      id = parseInt(id);
    }
    if (id && id > 0) {
      this.props.pointsReadChildren(
        config.dtPoint.columns,
        undefined,
        config.dtPoint.fkColumn,
        id,
        config.mapProjCode,
        0,
        0
      );
    }
  }

  readLines(id) {
    if (id && typeof id === "string") {
      id = parseInt(id);
    }
    this.props.linesReadChildren(
      config.dtLine.columns,
      undefined,
      config.dtLine.fkColumn,
      id,
      config.mapProjCode,
      0,
      0
    );
  }

  getFeatureStyleTrip = (f) => {
    const name = f.get("name");
    return new Style({
      stroke: new Stroke({
        color: "rgb(204, 23, 164)",
        width: 3,
        lineDash: [4, 8],
        lineDashOffset: 6,
      }),
      text: new Text({
        text: name || "Ikke tilgjengelig",
        fill: new Fill({
          color: "white",
        }),
        stroke: new Stroke({
          color: "grey",
        }),
        offsetX: 20,
        offsetY: 0,
        backgroundFill: new Fill({
          color: "#003b5c",
        }),
        textAlign: "left",
        font: "14px sans-serif",
        padding: [5, 5, 5, 5],
      }),
    });
  };

  getFeatureStylePoint = (f) => {
    const name = f.get("name");
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: "#f0a055",
          opacity: 100,
        }),
        radius: 10,
      }),
      text: new Text({
        text: name || "Ikke tilgjengelig",
        fill: new Fill({
          color: "white",
        }),
        stroke: new Stroke({
          color: "grey",
        }),
        offsetX: 20,
        offsetY: 0,
        backgroundFill: new Fill({
          color: "#003b5c",
        }),
        textAlign: "left",
        font: "14px sans-serif",
        padding: [5, 5, 5, 5],
      }),
    });
  };

  render() {
    const { tripPoints, tripLines } = this.props;

    this.props.zoomToExtent(tripLines.records);

    return (
      <React.Fragment>
        {tripPoints && (
          <VectorSource
            features={tripPoints.records.map((r) =>
              olUtils.createFeatureFromWkt(r.geom_wkt, r)
            )}
            layerName="tripPoints"
            style={this.getFeatureStylePoint}
          />
        )}
        {tripLines && (
          <VectorSource
            features={tripLines.records.map((r) =>
              olUtils.createFeatureFromWkt(r.geom_wkt, r)
            )}
            layerName="tripLines"
            style={this.getFeatureStyleTrip}
          />
        )}
      </React.Fragment>
    );
  }
}

TripGeometryContainer.propTypes = {
  pointsReadChildren: PropTypes.func.isRequired,
  linesReadChildren: PropTypes.func.isRequired,
  tripPoints: PropTypes.object.isRequired,
  tripLines: PropTypes.object.isRequired,
  tripId: PropTypes.any,
  resetLineChildrenDt: PropTypes.func.isRequired,
  resetPointChildrenDt: PropTypes.func.isRequired,
  zoomToExtent: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripGeometryContainer);
